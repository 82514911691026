import LanguageDetector from "i18next-browser-languagedetector";
import de_res from "./languages/de.json";
import en_res from "./languages/en.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import it_res from "./languages/it.json";

export enum ELanguages {
    it = "it",
    de = "de",
    en = "en",
}

export const languages = {
    [ELanguages.it]: "Italiano",
    [ELanguages.en]: "English",
    [ELanguages.de]: "Deutsch",
};

const resources = {
    it: {
        translation: it_res,
    },
    en: {
        translation: en_res,
    },
    de: {
        translation: de_res,
    },
};

const options = {
    order: ["localStorage", "navigator"],
    lookupLocalStorage: "i18nextLng",
};
export const getLanguage = () => window.localStorage.getItem(options.lookupLocalStorage)!;

const initCallback = () => !resources[getLanguage() as TLanguages] && i18n.changeLanguage(ELanguages.it);

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            resources,
            fallbackLng: ELanguages.it,
            debug: true,
            detection: options,
            interpolation: {
                escapeValue: false,
            },
        },
        initCallback,
    );

export type TLanguages = keyof typeof languages;

export const changeLanguage = (language: string) => i18n.changeLanguage(language);

export default i18n;
