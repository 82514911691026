import { IconButtonNoStyle, IconButtonUnderlined, IconButtonUnderlinedLink, IconButtonWithSvg } from "../../style/buttons/buttons";

interface IBtn extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text?: string;
    withOutline?: boolean;
}
export const Btn = ({ className, text, type, ...args }: IBtn) => (
    <button {...args} className={className ? className : "btn btn-primary complementary-2-bg-b1"} type={type ? type : "button"}>
        {text}
    </button>
);

export const BtnOutLine = ({ className, text, type, ...args }: IBtn) => (
    <button {...args} className={className ? className : "btn btn-outline-primary"} type={type ? type : "button"}>
        {text}
    </button>
);
export const UnderlinedBtn = ({ className, text, type, ...args }: IBtn) => (
    <IconButtonUnderlined {...args} className={className ? className : ""} type={type ? type : "button"}>
        {text}
    </IconButtonUnderlined>
);
export const IconBtn = ({ className, type, children, withOutline, ...args }: IBtn) => (
    <IconButtonNoStyle {...args} type={type ? type : "button"} $border={withOutline ? true : false}>
        {children}
    </IconButtonNoStyle>
);
export const IconBtnWithText = ({ className, text, type, children, withOutline, ...args }: IBtn) => (
    <IconButtonWithSvg {...args} type={type ? type : "button"}>
        {text}
        {children}
    </IconButtonWithSvg>
);
export const UnderlinedBtnLink = ({ className, text, type, ...args }: IBtn) => (
    <IconButtonUnderlinedLink {...args} className={className ? className : ""} type={type ? type : "button"}>
        {text}
    </IconButtonUnderlinedLink>
);
