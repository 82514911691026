import { AccountInfo, AuthenticationResult, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { loginRequest, msalConfig, tokenRequest } from './azureAd';

interface IAcquireToken extends AuthenticationResult {}

interface IAccountInfo extends AccountInfo {}

interface ISilentRequest extends SilentRequest {}

export const _msal = await PublicClientApplication.createPublicClientApplication(msalConfig);

export interface IAuthenticationResponse extends AuthenticationResult {}

export interface ITokenResponse {
    accessToken: string;
    tokenType: string;
    expiresOn?: string;
    refreshToken?: string;
}

const azureADHelper = () => {
    const login = async (): Promise<IAuthenticationResponse> => {
        let authResponse = await _msal.loginPopup(loginRequest);
        _msal.setActiveAccount(authResponse.account);
        return authResponse;
    };

    const logout = async (): Promise<void> => await _msal.logoutRedirect();

    const resetPassword = async (): Promise<void> => await _msal.loginRedirect();

    const requestAccessToken = async (): Promise<ITokenResponse | null> => {
        let token: IAcquireToken;
        let request: ISilentRequest = {
            ...tokenRequest,
            account: _msal.getActiveAccount() as IAccountInfo,
            scopes: [process.env.SCOPE ?? ''],
        };
        try {
            token = await _msal.acquireTokenSilent(request);
            return {
                accessToken: token.idToken,
                tokenType: token.tokenType,
            };
        } catch (error) {
            await _msal.acquireTokenRedirect(request);
            return null;
        }
    };

    const getActiveAccount = (): AccountInfo | null => _msal.getActiveAccount();

    return {
        login,
        logout,
        resetPassword,
        requestAccessToken,
        getActiveAccount,
    };
};

export default azureADHelper();
