import { ChangeEvent, useId } from "react";

import ErrorText from "../ErrorText/ErrorText";
import { Input } from "design-react-kit";
import { get } from "lodash";
import { useFormikContext } from "formik";

interface ITextInputProps {
    name: string;
    label: string;
    readOnly?: boolean;
    required?: boolean;
    description?: string;
}

export const TextInput = (props: ITextInputProps) => {
    const { values, setFieldValue, errors } = useFormikContext();
    const { name, label, readOnly, required, description } = props;
    const id = useId();
    const value = get(values, `[${name}]`);
    const error = get(errors, `[${name}]`);
    let className = !!value ? "active " : "";

    return (
        <div>
            <Input
                name={name}
                label={label}
                required={required}
                readOnly={readOnly}
                id={id}
                placeholder={label}
                value={value}
                className={!!error ? "is-invalid " + className : className}
                infoText={description}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)}
            />
            {!!error && <ErrorText text={error} />}
        </div>
    );
};
