import styled from "styled-components";

export const IconButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #f90;
    background-color: #fff;
    border-radius: 0.25rem;
`;
export const IconButtonWithSvg = styled.button`
    display: flex;
    padding: 12px 24px;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    white-space: initial;
    text-decoration: none;
    box-shadow: none;
    border: 1px solid #f90;
    background-color: #f90;
    color: #fff;
    gap: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
    & svg {
        width: 20px;
        fill: white;
        display: flex !important;
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.65;
    }
`;

export const IconButtonNoStyle = styled.button<{ $border?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
    border: ${(props) => (props.$border ? "1px solid #0066cc" : "none")};
    background-color: transparent;
    &:hover {
        opacity: 0.4;
    }
    & svg {
        width: 2rem;
        height: 2rem;
    }
`;
export const IconButtonUnderlined = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 2.5rem;
    border: none;
    color: #06c;
    background-color: #fff;
    margin-bottom: 1rem;
    &.active {
        border-bottom: 1px solid #06c;
    }
    &:hover {
        opacity: 0.8;
    }
`;
export const IconButtonUnderlinedLink = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #06c;
    background-color: #fff;
    border-bottom: 1px solid #06c;

    &:hover {
        opacity: 0.8;
    }
`;
