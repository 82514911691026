import { Configuration, PopupRequest, SilentRequest } from '@azure/msal-browser';

let TENANT_ID = process.env.REACT_APP_TENANT_ID ?? '';
let CLIENTID = process.env.REACT_APP_CLIENTID ?? '';
let REDIRECTURI = process.env.REACT_APP_REDIRECTURI ?? '';
let SCOPE = process.env.REACT_APP_SCOPE ?? '';
let AUTH = process.env.REACT_APP_AUTH ?? '';
let REACT_APP_KNOW_AUTH = process.env.REACT_APP_KNOW_AUTH ?? '';

export const msalConfig: Configuration = {
    auth: {
        clientId: CLIENTID, // This is the ONLY mandatory field; everything else is optional.
        authority: AUTH, //Choose sign-in user-flow as your default.
        knownAuthorities: [REACT_APP_KNOW_AUTH], // Mark your B2C tenant's domain as trusteda.
        redirectUri: REDIRECTURI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};

export const loginRequest: PopupRequest = {
    scopes: [SCOPE],
};

export const tokenRequest: SilentRequest = {
    scopes: [SCOPE],
    forceRefresh: false,
};
