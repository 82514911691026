import styled from "styled-components";

export const StyledH3 = styled.h3`
    color: #f90;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & .input-number-add,
    .input-number-sub {
        display: none;
    }
`;
export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
`;
export const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const PageTitle = styled.h1`
    font-size: 36px;
    line-height: 55px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #191919;
    margin: 0;
`;
export const PageDescription = styled.p`
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #191919;
    margin: 0;
`;

export const RowInputsWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    & .form-group,
    .select-wrapper {
        min-width: 50%;
    }
`;
export const ToastWrapper = styled.div`
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
`;
export const FileInfoBox = styled.div`
    width: 100%;
    display: flex;
    padding: 2rem;
    height: 7rem;
    align-items: center;
    background-color: #f2f7fc;
    gap: 4rem;
    overflow-x: auto;
    overflow-y: hidden;
`;

export const FormatContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 5rem;
    justify-content: center;
    & p {
        color: #003366;
        font-size: 1rem;
        margin-bottom: 0;
    }
    & div {
        font-weight: 600;
        color: #003366;
        font-size: 1rem;
    }
`;
export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 2rem 0;
    width: 100%;
    & .form-group {
        margin-bottom: 0px;
    }
    & button {
        max-width: 12rem;
    }
`;
export const Description = styled.span`
    padding: 0 8px;
    font-size: 0.875rem;
`;
export const ContainerForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    max-width: 90%;
    flex-grow: 1;
    & .form-group,
    .select-wrapper {
        min-width: 80%;
        margin-bottom: 0;
    }
`;

export const NotificationWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    min-height: 3rem;
    & h2 {
        display: flex;
        align-items: center;
        margin: 0;
        gap: 1rem;
    }
    & svg {
        fill: red;
    }
`;
export const NotificationPipe = styled.div`
    width: 0.3rem;
    background-color: red;
    border-radius: 8px 0 0 8px;
`;
