import Dropdown from "react-bootstrap/Dropdown";
import { get } from "lodash";
import styled from "styled-components";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

const StyledDropdown = styled(Dropdown)`
    display: flex;
    padding: 0;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    justify-content: space-between;
    background-color: #f90 !important;
    font-size: 1rem;
    padding: 0.5rem;
    font-weight: normal;
    align-items: center;
    gap: 0.5rem;
`;
const StyledDropdownItem = styled(Dropdown.Item)`
    text-decoration: none;
    color: #f90 !important;
`;

const Avatar = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    let activeAccount = instance.getActiveAccount();
    const cf = get(activeAccount, "idTokenClaims.extension_fiscalNumber", "");
    if (!activeAccount && !cf) return null;
    const lastName = get(activeAccount, "idTokenClaims.family_name", "");
    const firstName = get(activeAccount, "idTokenClaims.given_name", "");
    return (
        <div className="nav-item dropdown">
            <StyledDropdown className="p-2 nav-link dropdown-toggle">
                <StyledDropdownToggle variant="success" id="dropdown-basic">
                    <div className="d-flex align-items-center justify-content-around flex-wrap flex-sm-nowrap">
                        <div className="avatar avatar-red complementary-2-bg-b2">
                            <p aria-hidden="true">{`${firstName[0] ?? ""}${!!lastName ? lastName[0] : "User"}`}</p>
                            <span className="visually-hidden">
                                {firstName ?? ""} {lastName ?? "U"}
                            </span>
                        </div>
                    </div>
                </StyledDropdownToggle>
                <Dropdown.Menu>
                    <StyledDropdownItem
                        onClick={() => {
                            instance.logoutRedirect();
                        }}
                    >
                        {t("generic.logout")}
                    </StyledDropdownItem>
                </Dropdown.Menu>
            </StyledDropdown>
        </div>
    );
};

export default Avatar;
