import { ELanguages, changeLanguage, languages } from "../../localization";

import CustomSvg from "../Svg/CustomSvg";
import Dropdown from "react-bootstrap/Dropdown";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyledDropdown = styled(Dropdown)`
    display: flex;
    padding: 0;
`;
const StyledDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    justify-content: space-between;
    background-color: #f90 !important;
    font-size: 1rem;
    padding: 0.5rem;
    font-weight: normal;
    align-items: center;
    gap: 0.5rem;
`;
const StyledDropdownItem = styled(Dropdown.Item)`
    text-decoration: none;
    color: #f90 !important;
`;

const LanguageBtn = () => {
    const { i18n } = useTranslation();

    const isIt = i18n.language === ELanguages.it;

    return (
        <div className="nav-item dropdown">
            <StyledDropdown className="p-2 nav-link dropdown-toggle">
                <StyledDropdownToggle variant="success" id="dropdown-basic">
                    {isIt ? "IT" : "DE"}
                    <CustomSvg className="icon icon-white" iconName="it-expand" />
                </StyledDropdownToggle>
                <Dropdown.Menu>
                    <StyledDropdownItem onClick={() => changeLanguage(isIt ? ELanguages.de : ELanguages.it)}>{isIt ? languages[ELanguages.de] : languages[ELanguages.it]}</StyledDropdownItem>
                </Dropdown.Menu>
            </StyledDropdown>
        </div>
    );
};

export default LanguageBtn;
