import "react-tagsinput/react-tagsinput.css";
import "./Home.scss";

import { FlexCenter, StyledH3 } from "../style";
import { cryptData, decryptData } from "../utils";

import CertificateForm from "../components/CertificateForm/CertificateForm";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface IHomeProps {}

const StyledFormContainer = styled.div`
    margin-top: 1rem;
    padding: 2rem;
    gap: 1rem;
`;

const Home = (props: IHomeProps) => {
    const { t } = useTranslation();

    return (
        <div className="container mt-3">
            <div className="card-wrapper card-space">
                <StyledFormContainer className="card card-bg">
                    <FlexCenter>
                        <StyledH3>{t("generic.createCertificate")}</StyledH3>
                    </FlexCenter>
                    <CertificateForm />
                </StyledFormContainer>
            </div>
        </div>
    );
};

export default Home;
