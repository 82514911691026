import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Dispatch, SetStateAction, createContext, useEffect, useState } from "react";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Landing from "./pages/Landing";
import Loading from "./components/Loading/Loading";
import { RouterProvider } from "react-router-dom";
import { adminPath } from "./route";
import { get } from "lodash";
import { loginRequest } from "./msal/azureAd";
import styled from "styled-components";

interface IAppProps {}

export const LoggedUserContext = createContext<{
    loggedUser: { userId: number; cf: string; msgs: { messageIt: string; messageDe: string; id: string }[] };
    setLoggedUser: Dispatch<
        SetStateAction<{
            userId: number;
            cf: string;
            msgs: { messageIt: string; messageDe: string; id: string }[];
        }>
    >;
}>({ loggedUser: { userId: -1, cf: "", msgs: [] }, setLoggedUser: () => {} });

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const App = (props: IAppProps) => {
    const { accounts, instance } = useMsal();
    const [loading, setLoading] = useState<boolean>(false);
    const [loggedUser, setLoggedUser] = useState<{ userId: number; cf: string; msgs: { messageIt: string; messageDe: string; id: string }[] }>({ userId: -1, cf: "", msgs: [] });

    const checkRedirect = async () => {
        setLoading(true);
        try {
            await instance.handleRedirectPromise();
            let activeAcc = instance.getActiveAccount();
            let tokenSilent;

            if (!activeAcc && accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
                tokenSilent = await instance.acquireTokenSilent({ account: accounts[0], ...loginRequest });
            }

            if (!!activeAcc) {
                tokenSilent = await instance.acquireTokenSilent({ account: activeAcc, ...loginRequest });
            }
            if (!!tokenSilent) {
                const cf = get(accounts, "[0].idTokenClaims.extension_fiscalNumber", "");
                setLoggedUser({ ...loggedUser, cf });
                localStorage.setItem("token", tokenSilent.accessToken);
            }
        } catch (err) {
            console.log(err);
            if (err instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                return instance.acquireTokenRedirect(loginRequest);
            } else {
                instance.logoutRedirect();
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkRedirect();
    }, [accounts]);

    return (
        <>
            <AuthenticatedTemplate>
                {loading ? (
                    <LoadingContainer>
                        <Loading />
                    </LoadingContainer>
                ) : (
                    <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
                        <RouterProvider router={adminPath} />
                    </LoggedUserContext.Provider>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Landing />
            </UnauthenticatedTemplate>
        </>
    );
};

export default App;
