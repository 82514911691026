import Header from "./Header";
import { Outlet } from "react-router-dom";
import { ReactNode } from "react";
import styled from "styled-components";

interface ILayoutProps {
    children?: ReactNode;
}
const Page = styled.div`
    display: flex;
`;
const Container = styled.div`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 168px);
`;
const Layout = (props: ILayoutProps) => (
    <>
        <Header />
        <Page>
            <div className="container">
                <div className="row">
                    <div className="col-12">{props.children ? props.children : <Outlet />}</div>
                </div>
            </div>
        </Page>
    </>
);

export default Layout;
