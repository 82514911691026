import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "typeface-lora";
import "typeface-roboto-mono";
import "typeface-titillium-web";
import "./index.css";

import App from "./App";
import { FontLoader } from "design-react-kit";
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import { Toaster } from "react-hot-toast";
import { _msal } from "./msal/AdHelper";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <StrictMode>
        <MsalProvider instance={_msal}>
            <App />
            <Toaster />
            <FontLoader />
        </MsalProvider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
