import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export interface ILoadingProps {
    msg?: string;
}
const Loading = (props: ILoadingProps) => {
    const { msg } = props;

    return (
        <LoadingContainer>
            <Spinner />
            {!!msg && <p>{msg}</p>}
        </LoadingContainer>
    );
};

export default Loading;
