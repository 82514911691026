import * as React from "react";

import { NotificationPipe, NotificationWrapper } from "../style";

import CustomSvg from "../components/Svg/CustomSvg";
import { useTranslation } from "react-i18next";

interface INotFoundProps {}

const NotFound = (props: INotFoundProps) => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="row mb-5 mt-3">
                <div className="col-12 col-md-6">
                    <p>
                        <strong>404</strong>
                    </p>
                    <NotificationWrapper>
                        <NotificationPipe />
                        <h2 id="not2b-title" className="h5 ">
                            <CustomSvg className="icon" iconName="it-error" />
                            {t("errors.genericPath")}
                        </h2>
                    </NotificationWrapper>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
