import toast from "react-hot-toast";

export const toastError = (msg: string) =>
    toast.error(msg, {
        style: {
            border: "1px solid #f90",
            padding: "16px",
            color: "#f90",
        },
        icon: "!",
        iconTheme: {
            primary: "#f90",
            secondary: "#FFFAEE",
        },
    });
